<template>
  <section class="byPlatform">
    <div :class="['byPlatform__content', 'collapse__container', { 'collapse-active': !isCollapseOpen }]">
      <div :class="['byPlatform__header', 'collapse__header', { 'end-elements': isCollapseOpen }]">
        <p class="byPlatform__title">uso de plataformas respecto habitaciones</p>

        <button class="collapse__button" type="button" @click="toggleCollapse"><iconic name="chevronUp" /></button>
      </div>
      <div class="byPlatform__body collapse__body">
        <div class="byPlatform__body-header">
          <p class="byPlatform__subtitle">Filtros</p>
          <div class="byPlatform__body-toggles">
            <button type="button" :class="['byPlatform__body-toggles--showPlatforms', { checked: showPlatforms }]" @click="showPlatformsToggle">
              <iconic name="play" />
              <iconic name="play" />
            </button>
            <toggle
              v-for="(platform, idx) in platformOptions"
              @change="platformFilter($event, platform.value)"
              :checked="validateCurrentPlatform(platform.value)"
              :label="platform.abbreviation"
              :changeLabelPosition="true"
              :key="idx"
            />
          </div>
        </div>

        <div class="byPlatform__body-wrapper">
          <div class="byPlatform__body-platform" v-for="percentage in percentages" :key="percentage.name">
            <div class="byPlatform__body-platformHeader">
              <div class="byPlatform__body-platformData">
                <div :class="['byPlatform__body-platformData--platforms', { 'byPlatform__body-platformData--ambas': percentage.name === 'ambas' }]">
                  <iconic
                    name="play"
                    class="byPlatform__body-platformData--jasmin"
                    v-if="percentage.name === 'jasmin' || percentage.name === 'ambas'"
                  />
                  <iconic
                    name="play"
                    class="byPlatform__body-platformData--streamate"
                    v-if="percentage.name === 'stremeate' || percentage.name === 'ambas'"
                  />
                  <div class="byPlatform__body-platformData--none" v-if="percentage.name === 'ninguna'">
                    <iconic name="forbidden" />
                  </div>
                </div>
                <div>
                  <span class="byPlatform__body-platformData--platform">{{ `${percentage.total} ${percentage.name}` }}</span>
                </div>
              </div>
              <span class="byPlatform__body-percentage">{{ `${percentage.percentage}%` }}</span>
            </div>

            <div class="byPlatform__body-percentageBar">
              <div class="byPlatform__body-percentageBar byPlatform__body-percentageBar--fill" :style="{ width: percentage.percentage + '%' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["roomsData", "showPlatformsToggle", "showPlatforms"],
  components: {
    toggle: () => import("@/components/widgets/ToggleSwitch.vue"),
  },
  data() {
    return {
      heightFather: 0,
      platformOptions: [
        { text: "Dual", value: "double", abbreviation: "Dual" },
        { text: "Jasmin", value: "jasmin", abbreviation: "JS" },
        { text: "Stremaate", value: "stremeate", abbreviation: "ST" },
      ],
      isCollapseOpen: true,
    };
  },
  computed: {
    validateCurrentPlatform() {
      return (platformToFind) => {
        if ("platform" in this.$route.query) {
          return this.$route.query.platform === platformToFind;
        }
        return false;
      };
    },
    percentages() {
      const platformsMap = {
        jasmin: 0,
        stremeate: 0,
        ambas: 0,
        ninguna: 0,
      };
      let totalBusy = 0;

      this.roomsData.forEach((room) => {
        const model = room?.modelTemp || {};
        const isBroadcastingOnJasmin = model?.isBroadcastingOnJasmin;
        const isBroadcastingOnStreamate = model?.isBroadcastingOnStreamate;

        if (room?.modelTemp?._id) {
          if (isBroadcastingOnJasmin && isBroadcastingOnStreamate) {
            platformsMap["ambas"] += 1;
          } else if (isBroadcastingOnJasmin) {
            platformsMap["jasmin"] += 1;
          } else if (isBroadcastingOnStreamate) {
            platformsMap["stremeate"] += 1;
          } else {
            platformsMap["ninguna"] += 1;
          }
          totalBusy += 1;
        }
      });

      return Object.entries(platformsMap).map(([key, val]) => ({
        name: key,
        total: val,
        percentage: totalBusy === 0 ? 0 : Math.round((val / totalBusy) * 100),
      }));
    },
  },
  methods: {
    toggleElementCollapse(collapseInfo) {
      const { fatherIdOrClass, fatherHeight, isCollapseToClose, fatherHeightCollapsed } = collapseInfo;
      const fatherElement = document.querySelector(fatherIdOrClass);
      const howManyDecrease = fatherHeight >= 1000 ? 40 : 20;
      const intervalo = setInterval(() => {
        let alto = fatherElement.offsetHeight;

        if (isCollapseToClose) {
          if (alto <= fatherHeightCollapsed) {
            fatherElement.style.height = `${fatherHeightCollapsed}px`;
            clearInterval(intervalo);
          } else {
            fatherElement.style.height = alto - howManyDecrease + "px";
          }
        } else {
          if (alto >= fatherHeight) {
            clearInterval(intervalo);
          } else {
            fatherElement.style.height = alto + howManyDecrease + "px";
          }
        }
      }, 0);
    },
    toggleCollapse: function() {
      const fatherElement = document.querySelector(".byPlatform");

      if (this.isCollapseOpen) {
        this.heightFather = fatherElement.offsetHeight;
      }
      this.$emit("toggleElementCollapse", {
        fatherIdOrClass: `.byPlatform`,
        fatherHeight: this.heightFather,
        isCollapseToClose: this.isCollapseOpen,
        fatherHeightCollapsed: 60,
        key: "platforms",
      });
      this.isCollapseOpen = !this.isCollapseOpen;
    },
    platformFilter: function(evt, platform) {
      const isChecked = evt.target.checked;
      const { query } = this.$route;
      if (!isChecked) {
        const newQueries = {};
        Object.entries(query).forEach(([key, value]) => {
          if (key !== "platform") newQueries[key] = value;
        });
        this.$router.replace({ query: newQueries }).catch(() => {});
      }
      this.$emit("showCleanBtn", { flag: isChecked, platform });
    },
  },
};
</script>

<style lang="scss">
.byPlatform {
  &__content {
    background: #fff;
    border-radius: 9px;
    box-shadow: 0px 1px 4px 0px #00000040;
    height: 100%;
    &.collapse-active {
      .collapse__body {
        .byPlatform__subtitle {
          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }
      }
      .collapse__header {
        padding: 15px;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
  }
  &__title {
    font-size: 18px;
  }
  &__title,
  &__subtitle {
    font-family: $sec-font;
  }
  &__subtitle {
    display: none;
    font-size: 20px;
  }
  @include desktopWidth {
    &__subtitle {
      display: block;
    }
    &__title {
      font-size: 22px;
    }
  }
  &__body {
    @include Flex(column, flex-start, flex-start);
    gap: 20px;
    width: 100%;
    padding: 15px;
    padding-top: 0;
    transition: 0s;
    overflow: hidden;
    &.collapse-active {
      display: none;
    }
    &-header {
      @include Flex(column, flex-start, flex-start);
      gap: 20px;
      width: 100%;
      .toggleSwitch {
        &-switch {
          width: 32px;
          height: 21px;
          &:before {
            width: 13px;
            height: 13px;
          }
        }
        &-checkbox:checked + .toggleSwitch-switch {
          &:before {
            left: 15px;
          }
        }
      }
    }
    &-toggles {
      @include Flex(row, center, center);
      gap: 20px;
      margin-bottom: 20px;
      width: 100%;
      font-weight: bold;
      &--showPlatforms {
        display: flex;
        border: none;
        background: transparent;
        gap: 5px;
        position: relative;
        .iconic {
          color: #ccc;
          transition: color 0.3s ease-in-out;
          font-size: 20px;
          &:not(:last-of-type) {
            margin-right: -15px;
          }
        }
        &.checked {
          .iconic {
            &:not(:last-of-type) {
              color: #21619a;
              z-index: 10;
            }
            &:last-of-type {
              color: #bd0909;
            }
          }
          &::after {
            content: "";
            display: none;
          }
        }
        &::after {
          content: "";
          position: absolute;
          transition: all 0.3s ease-in-out;
          width: 4px;
          height: 30px;
          left: 12px;
          top: -5px;
          transform: rotate(35deg);
          background: #3a3a3a;
          border: 0.2px solid #fff;
          z-index: 20;
          border-radius: 10px;
        }
      }
    }

    &-wrapper {
      @include Flex(column, space-around, flex-start);
      width: 100%;
      height: 100%;
      gap: 10px;
    }
    &-platform {
      @include Flex(column, flex-start, flex-start);
      width: 100%;
      gap: 5px;
    }
    &-platformHeader {
      @include Flex(row, space-between);
      width: 100%;
      gap: 5px;
      font-family: $sec-font;
    }
    &-platformData {
      position: relative;
      @include Flex(row);
      gap: 10px;
      font-size: 16px;
      &--platforms {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        .iconic {
          font-size: 20px;
        }
      }
      &--ambas {
        justify-content: flex-start;
        .iconic:not(:last-of-type) {
          position: absolute;
          left: 10px;
        }
        .iconic:last-of-type {
          z-index: 10;
        }
      }
      &--jasmin {
        color: #bd0909;
        z-index: 10;
      }
      &--streamate {
        color: #21619a;
      }
      &--platform {
        font-family: $first_font;
        font-weight: bold;
        text-transform: uppercase;
      }
      &--none {
        display: flex;
      }
    }
    &-percentage {
      font-size: 19px;
      color: $primary-color;
    }
    &-percentageBar {
      width: 100%;
      height: 11px;
      background-color: #f0f0f0;
      border-radius: 7px;
      transition: 1s ease-in-out;
      &--fill {
        width: 0;
        max-width: 100%;
        height: 100%;
        background-color: $primary-color;
        border-radius: 7px;
      }
    }
    @include desktopWidth {
      &__title {
        width: 90%;
        text-align: left;
      }
      &-header {
        @include Flex(row, space-between, center);
      }
      &-toggles {
        @include Flex(row, flex-end, center);
        gap: 20px;
        margin-bottom: 0;
        width: 70%;
      }
    }
  }
}
</style>
